import './App.css';
// import {ContactModal} from './modals/contact';
import { Grid, Button, Stack, Tooltip } from '@mui/material';
// import { useState } from 'react';

function App() {
  // const [hideModal, setHideModal] = useState(true);

  // const toggleModal = () => {
  //   alert('toggleModal');
  //   const temp = hideModal;
  //   setHideModal(!temp);
  // }

  return (
    <div className="wrapper">
      <body className='body'>
        <Grid 
          container
          direction="row"
          justifyContent="center"
          alignItems="flex-start"
          spacing={{xs: 1, md: 3}}
          className='body-grid'>
          <Grid item className="header"  xs={6} md={4}>
            <div className='title'>Hi, my name's <big>Mickey</big><sub><small>(they/he)</small></sub></div>
          </Grid>
          <Grid item xs={12} md={8}>
            <div className='content'>I'm a fanatical cyclist, sci-fi geek (from <i>Frankenstein</i> to <i>Farscape</i>), reformed gamer, big fan of purple, and consumer of, like, sooo much peanut butter.</div>
          </Grid>
          <Grid item xs={12} md={7}>
            <div className='content'><Tooltip title="By night, I dress up like a bat and punch people.">By day</Tooltip>, I'm a software engineer. I've most recently used  Javascript, Node, React, AWS, MySQL/PostGres, and of course Git/GitLab.<br/>I'm comfortable with GitHub, Python, Typescript, Java, PHP, C++, C, and plenty of others. From queries to modals, I got you.</div>
          </Grid>
          <Grid item xs={12} md={5}>
            <div className='content'> I enjoy saying "I don't know", and solving problems that give me headaches. If you need a full-stack engineer who has a passion for UX/UI, can drop <i>exquisite</i> gifs in the Slack chat, and shares a name with a fictional mouse, look no further!</div>
          </Grid>
          <Grid item>
            <Stack direction='row' spacing={2}>
              {/* <Button classes={{root: "nav-button"}} onClick={toggleModal}>Contact</Button> */}
              <Button classes={{root: "nav-button"}} href='mailto:hi@mickeynash.com' target='_blank'>Email</Button>
              <Button classes={{root: "nav-button"}} href='https://github.com/mckymsh' target='_blank'>Github</Button>
              <Button classes={{root: "nav-button"}} href='https://www.linkedin.com/in/mickeynash/' target='_blank'>Linkedin</Button>
            </Stack>
          </Grid>
        </Grid>
      </body>
      {/* <ContactModal hidden={hideModal} closeFunction={toggleModal} submitFunction={handleSubmit}/> */}
    </div>
  );
}

export default App;
